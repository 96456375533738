type ErrorPayload = {
  error: string;
  error_code: string;
};

class API {
  private _baseUrl: string;
  private _accessToken: null | string = null;
  private _accessTokenHeaderLabel = "X-ACCESS-KEY";
  public onAuthFailure: () => void = () => {};
  private onServerError: (error: ErrorPayload) => void = () => {};

  constructor(baseUrl?: string) {
    this._baseUrl =
      // baseUrl || process.env.REACT_APP_BASE_URL || "https://liverpooladmin.northernskystar.com";
      // baseUrl ||
      // process.env.REACT_APP_BASE_URL ||
      // "https://drpolaris-admin-api.cubettech.in";
      // baseUrl || process.env.REACT_APP_BASE_URL || "https://admin.drpolaris.com";
      //baseUrl ||
      // process.env.REACT_APP_BASE_URL ||
      //"https://proofadmin.northernskystar.com";
      baseUrl ||
      process.env.REACT_APP_BASE_URL ||
      "drpolaris-admin-api-ind.cubettech.in";
  }

  private formatDestinationUrl = (
    endpoint: string,
    query: { [key: string]: any } = {}
  ): string => {
    const remoteUrl = endpoint.startsWith("http")
      ? endpoint
      : `${this._baseUrl}/${endpoint}`;
    if (Object.keys(query).length === 0) {
      return remoteUrl;
    }

    const paramsKeyValuePair = Object.keys(query).map((key) => {
      if (query[key] === null) {
        return key;
      }
      if (Array.isArray(query[key])) {
        return `${key}=${encodeURIComponent(query[key].join(","))}`;
      }
      return `${key}=${encodeURIComponent(query[key])}`;
    });

    const joinedParams = paramsKeyValuePair.reduce(
      (acl, curr) => `${acl}&${curr}`
    );

    return `${remoteUrl}?${joinedParams}`;
  };

  private fetchJson = async (
    endpoint: string,
    method: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    const destination = this.formatDestinationUrl(endpoint, query);
    if (method !== "get" && method !== "delete" && typeof body !== "object") {
      throw new Error("The body must be an object");
    }

    const bodyIsFormData = body instanceof FormData;
    let customHeaders: { [key: string]: string } = {};

    if (this._accessToken) {
      customHeaders[this._accessTokenHeaderLabel] = this._accessToken;
    }

    const headers = Object.assign(
      {},
      customHeaders,
      bodyIsFormData ? undefined : { "Content-Type": "application/json" }
    );

    const fetchOptions: RequestInit = {
      credentials: "include",
      // mode: "no-cors",
      method,
      headers,
    };

    if (method.toLowerCase() !== "get") {
      if (bodyIsFormData) {
        fetchOptions.body = body as FormData;
      } else {
        fetchOptions.body = JSON.stringify(body);
      }
    }

    try {
      const response = await fetch(
        destination.replace(/([^:]\/)\/+/g, "$1"),
        fetchOptions
      );
      let jsonResult: any = {};
      if (response.status !== 204) {
        jsonResult = await response.json();
      }
      if (response.status >= 200 && response.status <= 300) {
        return await jsonResult;
      }
      if (response.status === 401 || response.status === 403) {
        if (jsonResult.error === "access_token_expired") {
          try {
            const refreshToken = localStorage.getItem("refreshToken");
            console.log(refreshToken);
            const { access_token, error } = await this.fetchJson(
              "auth/renew",
              "post",
              undefined,
              { refresh_token: refreshToken }
            );
            if (error) {
              throw new Error(error);
            }
            this.setAccessToken(access_token);
            await this.fetchJson(endpoint, method, query, body);
            return;
          } catch (error) {
            // Well everything went wrong.
            this.onAuthFailure();
            return;
          }
        }
        return Promise.reject({ message: jsonResult.error });
      }

      if (response.status >= 500 && response.status <= 600) {
        this.onServerError({
          error: jsonResult.error,
          error_code: jsonResult.error_code,
        });
        return Promise.reject({ message: jsonResult.error });
      }
      return Promise.reject(jsonResult);
    } catch (error) {
      throw error;
    }
  };

  public get = async (endpoint: string, query?: Object) => {
    return await this.fetchJson(endpoint, "get", query);
  };

  public post = async (
    endpoint: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    return await this.fetchJson(endpoint, "post", query, body);
  };

  public patch = async (
    endpoint: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    return await this.fetchJson(endpoint, "PATCH", query, body);
  };

  public put = async (
    endpoint: string,
    query?: Object,
    body?: Object | FormData
  ) => {
    return await this.fetchJson(endpoint, "PUT", query, body);
  };

  public delete = async (endpoint: string, query?: Object) => {
    return await this.fetchJson(endpoint, "delete", query);
  };

  public setAccessToken = (token: string | null): void => {
    this._accessToken = token;
  };

  public setBaseUrl = (url: string): void => {
    this._baseUrl = url;
  };

  public setServerErrorHandler = (
    errorHandler: (error: ErrorPayload) => void
  ) => (this.onServerError = errorHandler);
}

export const api = new API();
